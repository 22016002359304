html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

::-moz-selection {
    background: #bd2cc1;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #bd2cc1;
    color: #fff;
    text-shadow: none;
}

body {
    color: #0a0309;
    font-display: optional;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.wf-active body {
    font-family: 'Roboto Slab', serif;
}

h2 {
  border-bottom: 1px solid #74119d;
  color: #74119d;
  font-size: 22px;
  font-weight: normal;
  margin: 20px 0;
  padding-bottom: 10px;
}

@media (min-width: 800px) {
  h2 {
    font-size: 32px;
    margin: 40px 0;
  }
}

p {
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 20px;
}

@media (min-width: 800px) {
  p {
    font-size: 16px;
    line-height: 26px;
  }
}

ul {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

@media (min-width: 800px) {
  ul {
    font-size: 16px;
    line-height: 26px;
  }
}

a {
  color: #bd2cc1;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  display: block;
  max-width: 100%;
}