.wrap {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

.header {
  margin: 30px 0;
  text-align: center;
}

.ib10 {
  display: inline-block;
  margin: 30px auto;
  width: 105px;
}

.btn {
  background-color: #74119d;
  border-radius: 2px;
  color: #ffffff;
  display: inline-block;
  max-width: 378px;
  padding: 10px 20px;
  text-align: center;
  transition: background-color 0.2s ease-in;
  width: 100%;
}

.btn:hover {
  background-color: #bd2cc1;
  text-decoration: none;
}

.screen {
  cursor: zoom-in;
  margin: 20px auto;
  max-width: 1200px;
}

.screen img {
  border-radius: 5px;
  margin: 10px auto;
}

@media (min-width: 800px) {
  .screen img {
    margin: 40px auto;
  }
}

.masonry {
  columns: 2 auto;
  grid-column-gap: 10px;
  margin: 20px auto 0;
  max-width: 1200px;
}

@media (min-width: 800px) {
  .masonry {
    grid-column-gap: 60px;
  }
}

.masonry .screen img {
  margin: 0 0 10px
}

@media (min-width: 800px) {
  .masonry .screen img {
    margin: 0 0 40px
  }
}

.cols {
  margin: 0 0 20px;
  text-align: justify;
}

@media (min-width: 800px) {
  .cols {
    column-gap: 60px;
    columns: 2 auto;
    margin: 0 0 40px;
  }
}

.skills {
  list-style: none;
  margin: 0 -5px 20px;
  padding: 0;
}

@media (min-width: 800px) {
  .skills {
    margin: -20px -5px 20px;
    
  }
}

.skills li {
  border-radius: 3px;
  border: 1px solid #000000;
  color: #000000;
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  font-size: 14px;
}

.features {
  list-style: none;
  margin: -5px -5px 20px;
  padding: 0;
}

.features li {
  background-color: #bd2cc1;
  border-radius: 3px;
  border: 1px solid #bd2cc1;
  color: #ffffff;
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  font-size: 14px;
}